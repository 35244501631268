<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>
  <div class="grid p-fluid">
    <div class="col-12 md:col-4">
      <div class="card">
        <Toast />
        <Toolbar class="mb-2">
          <template v-slot:start>
            <div class="my-2">
              <span class="p-float-label">
                <Dropdown
                  id="status"
                  v-model="coursestatus"
                  :options="courseitems"
                  :value="courseitems.value"
                  optionLabel="name"
                  placeholder="Select Course"
                  v-on:change="update_sub()"
                  :class="{ 'p-invalid': submitted && !selectstatus }"
                  required="true"
                ></Dropdown>
                <label for="Course">Course</label>
              </span>
              <br />

              <span class="p-float-label">
                <Dropdown
                  id="status"
                  v-model="subjectstatus"
                  :options="subjectitems"
                  :value="subjectitems.value"
                  optionLabel="name"
                  placeholder="Select Subject"
                  v-on:change="update_topic()"
                  :class="{ 'p-invalid': submitted && !selectstatus }"
                  required="true"
                ></Dropdown>
                <label for="Course">Subject</label>
              </span>

              <br />

              <span class="p-float-label">
                <Dropdown
                  style="width: 200px"
                  id="status"
                  v-model="topicstatus"
                  :options="topicitems"
                  :value="topicitems.value"
                  optionLabel="name"
                  placeholder="Select Subject"
                  :class="{ 'p-invalid': submitted && !selectstatus }"
                  required="true"
                  v-on:change="get_data"
                ></Dropdown>
                <label for="Course">Topic</label>
              </span>
              <br />
              <!-- <Button
                icon="pi pi-sync"
                class="p-button-danger mr-2"
                @click="refresh"
              /> -->
               <Button icon="pi pi-refresh" @click="refresh()" />
            </div>

            <!-- <Button
                icon="pi pi-search"
                class="p-button-success mr-2"
                @click="get_list"
              /> -->
            <!-- <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              /> -->
          </template>
        </Toolbar>
      </div>
    </div>

    <div class="col-12 md:col-8">
      <div class="card" style="height: 500px">
        <!-- <div v-html="storeD_LINK" v-show="show" ></div>   -->

        <!-- style="height: 250px" -->

        <div v-if="storeD_LINK">
          <iframe
            v-show="show"
            width="100%"
            height="500px"
            :src="storeD_LINK"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div v-else>
          <b>Data Not Found</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      material: [],
      show: false,
      storeD_LINK: "",
      uploadproductDialog: false,
      stdDialog: false,
      topicstatus: { name: "", value: "" },
      topicitems: [],
      courseitems: [],
      subjectitems: [],
      coursestatus: { name: "", value: "" },
      subjectstatus: { name: "", value: "" },
      subject: [],
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      file_attachmentsm: "",
      file_attachmentdpp: "",
      file_attachmentdppA: "",
      dropdownItems: [
        { name: "Motivational", value: "Motivational" },
        { name: "Interview", value: "Interview" },
      ],
      dropdownItems2: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      stdproducts: null,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "Active", value: "Active" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    await this.get_course_list();
    await this.get_subject_list();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    async update_topic() {
      var data = {
        id: parseInt(this.subjectstatus.value),
      };
      this.loading = true;
      var promise = apis.subjectwisetopiclist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.topicitems = [];
          for (var i = 0; i < info.length; i++) {
            this.topicitems.push({
              name:
                info[i].teacherName +
                "-" +
                info[i].schedule +
                "-" +
                info[i].topic,
              value: info[i].tscH_DE_ID,
            });
          }
        }
      });
    },

    async update_sub() {
      this.subjectitems = [];
      for (var i = 0; i < this.subject.length; i++) {
        // alert(this.subject[i].subject)
        if (this.subject[i].mjmB_ID == this.coursestatus.value) {
          this.subjectitems.push({
            name: this.subject[i].subject,
            value: this.subject[i].mjmS_ID,
          });
        }
      }
    },

    view_video(video) {
      // alert(video)
      window.open(video.data.embeD_LINK, "_blank");
    },
    view_std(info) {
      var data = {
        TSCH_DE_ID: info.tscH_DE_ID,
      };
      this.loading = true;
      var promise = apis.lectureattendedstudentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.stdproducts = response.data;
        this.stdDialog = true;
      });
    },
    get_subject_list: function () {
      var data = {
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };

      var promise = apis.subjectlist(data, this.id);
      promise.then((response) => {
        console.log(response);
        this.subject = response.data;
        var info = response.data;
        if (info) {
          this.subjectitems = [];
          for (var i = 0; i < info.length; i++) {
            this.subjectitems.push({
              name: info[i].subject,
              value: info[i].mjmS_ID,
            });
          }
        }
      });
    },

    get_course_list: function () {
      var data = {
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.batchlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.courseitems = [];
          for (var i = 0; i < info.length; i++) {
            this.courseitems.push({
              name: info[i].batch,
              value: info[i].mjmB_ID,
            });
          }
        }
      });
    },
    //a simple date formatting function
    dateFormat(inputDate, info) {},
    handleFileUploadsm() {
      this.file_attachmentsm = this.$refs.file_attachmentsm.files[0];

      //alert(this.$refs.file_attachmentsm.files[0].name);
    },
    handleFileUploaddpp() {
      this.file_attachmentdpp = this.$refs.file_attachmentdpp.files[0];

      //alert(this.$refs.file_attachmentdpp.files[0].name);
    },
    handleFileUploaddppA() {
      this.file_attachmentdppA = this.$refs.file_attachmentdppA.files[0];

      //alert(this.$refs.file_attachmentdppA.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {},
    refresh() {
      this.product = null;
      this.coursestatus = { name: "", value: "" };
      this.subjectstatus = { name: "", value: "" };
      this.topicstatus = { name: "", value: "" };
      this.show = false;
    },

    async get_data() {
      var data = {
        // id:parseInt(localStorage.getItem("id")),
        Limit: this.limit,
        page_no: this.page_no,
        count: false,

        Batch: parseInt(this.coursestatus.value),
        subject: parseInt(this.subjectstatus.value),
        TSCH_DE_ID: parseInt(this.topicstatus.value),
      };
      this.isLoadingModel = true;

      var promise = apis.studymaterialmaster(data, this.id);
      promise.then((response) => {
        this.isLoadingModel = false;

        console.log(response);
        this.products = response.data;
        this.material = response.data;
        this.storeD_LINK = "";
        if (this.material || response) {
          this.storeD_LINK = this.material[0].uploaD_CONTENT;
          // this.storeD_LINK = this.material[0].storeD_LINK;
        }
        this.show = true;

        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });
      await this.get_count();
    },

    async get_list() {},
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async Addyear() {
      this.submitted = true;
      if (
        !this.product.enrollmenT_YEAR ||
        this.product.enrollmenT_YEAR.length > 4
      ) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter valid Enrollment Year",
          life: 3000,
        });
        return false;
      }
      if (!this.product.exaM_YEAR || this.product.exaM_YEAR.length > 7) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter valid Exam Year",
          life: 3000,
        });
        return false;
      }

      //edit
      if (this.product.mjyeaR_ID) {
        var data = {
          mjyeaR_ID: this.product.mjyeaR_ID,
          enrollmenT_YEAR: this.product.enrollmenT_YEAR,
          exaM_YEAR: this.product.exaM_YEAR,

          updated_by: localStorage.getItem("full_name"),
        };
        this.isLoadingModel = true;
        var promise = apis.edityearmaster(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {
        var data1 = {
          enrollmenT_YEAR: parseInt(this.product.enrollmenT_YEAR),
          exaM_YEAR: this.product.exaM_YEAR,
          createD_by: localStorage.getItem("id"),
        };
        this.isLoadingModel = true;
        var promises = apis.addyearmaster(data1);
        promises
          .then((responseapi) => {
            this.isLoadingModel = false;
            this.productDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
      this.selectstatus2 = { name: "", value: "" };
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.file_attachmentsm = "";
      this.file_attachmentdpp = "";
      this.file_attachmentdppA = "";
      this.product = { ...product };
      this.uploadproductDialog = true;
      // this.selectstatus.value = this.product.type;
      // this.selectstatus.name = this.product.type;

      // this.selectstatus2.value = this.product.status;
      // this.selectstatus2.name = this.product.status;

      // this.file_attachmentsm = this.product.image;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteVideo() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deletebatchmaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
